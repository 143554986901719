import React, {useState} from 'react'
import {FaBars, FaTimes, FaGithub, FaLinkedin} from 'react-icons/fa'
import {HiOutlineMail} from 'react-icons/hi'
import {BsFillPersonLinesFill} from 'react-icons/bs'
import Logo from '../assets/logo.png'
import {Link} from 'react-scroll'


const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)
  return (
    <div className = 'fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#060e1b] text-gray-300'>
        <div>
            <img src={Logo} alt="logo" style={{width:'100px'}}/>
        </div>
        {/* Menu */}
        <ul className='hidden md:flex'>
            <li>      
                <Link to="Home" smooth={true} duration={500}>
                    <p className='transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-125'>Home</p>
                </Link>
            </li>
            <li>
                <Link to="About" smooth={true} duration={500}>
                    <p className='transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-125'>About</p>
                </Link>
            </li>
            <li>
                <Link to="Skills" smooth={true} duration={500}>
                    <p className='transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-125'>Skills</p>
                </Link>
            </li>
            <li>
                <Link to="Work" smooth={true} duration={500}>
                    <p className='transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-125'>Work</p>
                </Link>
            </li>
            <li>
                <Link to="Contact" smooth={true} duration={500}>
                    <p className='transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-125'>Contact Me</p>
                </Link>
            </li>
        </ul>
        {/* Hamburger menu */}        
            <div onClick = {handleClick} className='md:hidden z-10'>
                {!nav ? <FaBars className = 'cursor-pointer' />: <FaTimes className = 'cursor-pointer' />}
            </div>
        {/* Mobile menu */}
        <ul className= {!nav ? 'hidden' : 'transition ease-in-out delay-75 absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center'}>
            <li className='py-6 text-4xl'>
                <Link onClick={handleClick} to="Home" smooth={true} duration={500} className='hover:scale-150'>
                    <p className='transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-125'>Home</p>
                </Link>                
            </li>
            <li className='py-6 text-4xl'>
                <Link onClick={handleClick} to="About" smooth={true} duration={500}>
                    <p className='transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-125'>About Me</p>
                </Link>
            </li>
            <li className='py-6 text-4xl'>
                <Link onClick={handleClick} to="Skills" smooth={true} duration={500}>
                    <p className='transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-125'>Skills</p>
                </Link>
            </li>
            <li className='py-6 text-4xl'>
                <Link onClick={handleClick} to="Work" smooth={true} duration={500}>
                    <p className='transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-125'>Work</p>  
                </Link>
            </li>
            <li className='py-6 text-4xl'>
                <Link onClick={handleClick} to="Contact" smooth={true} duration={500}>
                    <p className='transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-125'>Contact Me</p>
                </Link>
            </li>
        </ul>

        {/* Social Icons */}
        <div className='fixed flex-col top-[35%] left-0 hidden lg:flex'>
            <ul>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600'>
                    <a className='flex justify-between items-center w-full text-gray-300' target='_blank' rel='noreferrer' href = "https://www.linkedin.com/in/philip-yi123/">LinkedIn<FaLinkedin size = {30} /> </a>
                </li>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]'>
                    <a className='flex justify-between items-center w-full text-gray-300' target='_blank' rel='noreferrer' href = "https://github.com/Gitphiyi">Github<FaGithub size = {30} /> </a>
                </li>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]'>
                    <a className='flex justify-between items-center w-full text-gray-300' target='_blank' rel='noreferrer' href = "mailto: philip.yi.work@gmail.com">Email<HiOutlineMail size = {30} /> </a>
                </li>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#565f69]'>
                    <a className='flex justify-between items-center w-full text-gray-300' target='_blank' rel='noreferrer' href = "https://drive.google.com/file/d/1VW-5M_y4YOsZxkUoKUK82Rjdwkp39SpS/view?usp=sharing">Resume<BsFillPersonLinesFill size = {30} /> </a>
                </li>
            </ul>
        </div>
    </div>
  )
}

export default Navbar