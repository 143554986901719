import React from 'react'
import {FaGithub, FaLinkedin} from 'react-icons/fa'
import { GoMail } from "react-icons/go";


const Footer = () => {
  return (
    <div className='w-full h-auto flex flex-col justify-center items-center px-4 m-0 bg-[#060e1b] text-gray-300'>
        <div className= "text-4xl flex flex-row justify-center align-middle mt-3 p-3 gap-10">
            <a className = 'bg-transparent cursor-pointer' href='https://www.linkedin.com/in/philip-yi123/' target = '_blank' rel='noreferrer'><FaLinkedin className='bg-transparent'/></a>
            <a className = 'bg-transparent cursor-pointer' href='https://github.com/Gitphiyi' target = '_blank' rel='noreferrer'><FaGithub className='bg-transparent'/></a>
            <a className = 'bg-transparent cursor-pointer' href='mailto: philip.yi@gmail.com' rel='noreferrer'><GoMail className='bg-transparent'/></a>  
        </div>
        <p className='mb-3'> Philip Yi @ 2023</p>
    </div>
  )
}

export default Footer