import React from 'react'
import profilepic from '../assets/LinkedInPic.jpg'

const About = () => {
  return (
    <div name = 'About' className='w-full h-screen bg-[#0a192f] text-gray-300'>
        <div className='flex flex-col justify-center items-center w-full h-full'>
            <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
                <div className = 'sm:text-right pb-8 pl-4'>
                    <p className='text-4xl font-bold inline border-b-4 border-pink-600'>
                        About
                    </p>
                </div>
                <div></div>
            </div>
            <div className='max-w-[1000px] w-full grid md:grid-cols-2 gap-8 px-4'>
                <div className='md:text-right text-4xl font-bold'>
                    <p>Hi, I'm Philip Yi, nice to meet you.</p>
                </div>
                <div>
                    <img className = "w-40 ml-2 float-right hidden md:block" src = {profilepic} alt = "me!"/>
                    <p className='mb-2'>
                        <b>I am a student at Duke University majoring in Computer Science and 
                        Math.</b> I am interested in Machine Learning, Robotics, Systems Engineering, and Back-End Development, so please contact me 
                        if you are interested in working with me on any of these topics!
                    </p>
                    <p className='mb-2'>
                        In 2018, I started programming in Java on Android Studio to localize and maneuver a robot autonomously
                        in FIRST robotics. Since then I have continued to do various projects and schoolwork, 
                        some of which can be found in the Projects section. For the Summer of 2023 I worked as a Data Analytics Intern at 
                        Caterpillar Inc. where I used machine learning to help predict vessel fuel consumption. For the Summer of 2024, I worked as a Software
                        Engineer Intern at DraftKings Inc. where I built the first data pipeline for NCAA Women's Basketball, allowing the Data Science team
                        to create in house models for NCAA Women's Basketball betting lines.
                    </p>
                    <p>
                        <b>Outside of school work</b> I love to lift weights, listen to R&B, watch anime, and run alongside the Chicago lakeshore!
                    </p>
                </div>
            </div>
            <div className='h-[100px] w-full flex justify-center'><a className='text-white text-center py-4 border-2 hover:bg-pink-600 hover:border-pink-600 rounded mt-10 w-[200px]' target='_blank' rel='noreferrer' href = "https://drive.google.com/file/d/1nVxiRrrz1AUyGLVaTJJOz_7Pxx8R46ao/view?usp=sharing">Resume</a></div>
        </div>
    </div>
  )
}

export default About