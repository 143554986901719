import {HiArrowNarrowRight} from 'react-icons/hi'
import { FaChevronDown } from 'react-icons/fa'
import {Link} from 'react-scroll'

const Home = () => {
    return (
        <div name = 'Home' className='w-full h-screen bg-[#0a192f]'>
            {/* Container */}
            <div className='max-w-[1080px] mx-auto px-8 flex flex-col justify-between content-end h-full'>
                <div className='mt-40'>
                    <p className='text-xl font-bold text-pink-600'>Hi, my name is</p>
                    <h1 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6]'>Philip Yi</h1>
                    <h2 className='text-4xl mb-10 sm:text-7xl font-bold text-[#8892b0]'>I'm an Undergraduate Student.</h2>
                    <div>
                        <Link to="Work" smooth={true} duration={500}>
                            <button className='group text-white border-2 px-6 py-3 my-2 flex items-center hover:bg-pink-600 hover:border-pink-600'>View Work
                            <span className='group-hover:rotate-90 duration-300'>
                                <HiArrowNarrowRight className='ml-3'/>
                            </span>
                            </button>
                        </Link>
                    </div>
                </div>
                <div className='mb-10 text-gray-50 flex justify-center'>
                    <Link to="About" smooth = {true} duration={500}><FaChevronDown size={30} className='hover:cursor-pointer hover:animate-pulse'/></Link>
                </div>
            </div>
        </div>
    )
}

export default Home