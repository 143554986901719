import React from 'react'
import Python from '../assets/Python.png'
import Pytorch from '../assets/pytorch.png'
import Scikit from '../assets/scikit.png'
import java from '../assets/java.png'
import react from '../assets/react.png'
import mysql from '../assets/mysql.png'


const Skills = () => {
  return (
    <div name = "Skills" className='bg-[#0a192f]'>
        {/* Container */}
        <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center items-left w-full h-screen text-gray-300'>
            <div>
                <p className='text-4xl font-bold inline border-b-4 border-pink-600'>Skills</p>
                <p className='py-4'>These are some technologies I've worked with</p>
            </div>

            <div className='w-full grid grid-cols-2 sm:grid-cols-3 gap-4 text-center py-8'>
                <div className='flex flex-col justify-center shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className = 'w-20 mx-auto' src={Python} alt = "Python" />
                    <p>PYTHON</p>
                </div>
                <div className='flex flex-col justify-center shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className = 'w-20 mx-auto' src={Pytorch} alt = "Python" />
                    <p>PYTORCH</p>
                </div>
                <div className='flex flex-col justify-center shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className = 'w-20 mx-auto' src={Scikit} alt = "scikit learn" />
                    <p>SCIKIT LEARN</p>
                </div>
                <div className='flex flex-col justify-center shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className = 'w-20 mx-auto' src={java} alt = "Java" />
                    <p>JAVA</p>
                </div>
                <div className='flex flex-col justify-center shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className = 'w-20 mx-auto' src={react} alt = "React.JS" />
                    <p>React.JS</p>
                </div>
                <div className='flex flex-col justify-center shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className = 'w-20 mx-auto' src={mysql} alt = "Mysql" />
                    <p>MYSQL</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Skills