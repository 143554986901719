import React from 'react'
import CatImg from '../assets/cat.jpeg'
import Cat2Img from '../assets/cat2.jpeg'
import SpotifyImg from '../assets/spotify.jpeg'
import FirstImg from '../assets/ftc.jpg'
import VexImg from '../assets/vexu.png'
import WebsiteImg from '../assets/websiteimg.png'


const Work = () => {
  return (
    <div name = 'Work' className='bg-[#0a192f] w-full md:h-screen text-gray-300'>
        <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-pink-600'>Work</p>
                <p className='py-6'>These are some of the latest projects that I have been working on. Some projects may have no demo due to containing sensitive information.</p>
            </div>
            {/* Container */}
            <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>

                {/* Grid */}
                {/* Content-div is custom css in index.css */}
                <div style = {{backgroundImage: `url(${Cat2Img})`}}
                className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto text-center content-div'>
                    {/* Hover Effects */}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-2xl font-bold text-white tracking-wider p-4'>
                            Software Engineering Intern At Caterpillar
                        </span>
                        <div className='pt-8 text-center'>
                            <a target = '_blank' href = "https://github.com/Gitphiyi?tab=repositories">
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'> Demo </button>
                            </a>
                        </div>
                    </div>  
                </div>

                <div style = {{backgroundImage: `url(${CatImg})`}}
                className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto text-center content-div'>
                    {/* Hover Effects */}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-2xl font-bold text-white tracking-wider p-4'>
                            Data Analytics Intern At Caterpillar
                        </span>
                        <div className='pt-8 text-center'>
                            <a target = '_blank' href = "https://github.com/Gitphiyi?tab=repositories">
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'> Demo </button>
                            </a>
                        </div>
                    </div>  
                </div>

                <div style = {{backgroundImage: `url(${SpotifyImg})`}}
                className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto text-center content-div'>
                    
                    {/* Hover Effects */}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-2xl font-bold text-white tracking-wider p-4'>
                            Spotify Multi-Tool Package
                        </span>
                        <div className='pt-8 text-center'>
                            <a target = '_blank' href = "https://github.com/Gitphiyi?tab=repositories">
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'> Demo </button>
                            </a>
                        </div>
                    </div>  
                </div>

                <div style = {{backgroundImage: `url(${FirstImg})`}}
                className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto text-center content-div'>
                    
                    {/* Hover Effects */}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-2xl font-bold text-white tracking-wider p-4'>
                            FIRST FTC Team 17576 Founder & Mechanical Lead
                        </span>
                        <div className='pt-8 text-center'>
                            <a target = '_blank' href = "https://youtu.be/Iw0gTh2wP_o">
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'> Demo </button>
                            </a>
                        </div>
                    </div>  
                </div>

                <div style = {{backgroundImage: `url(${VexImg})`}}
                className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto text-center content-div'>
                    
                    {/* Hover Effects */}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-2xl font-bold text-white tracking-wider p-4'>
                            Duke VexU Robotics Mechanical & Software Lead
                        </span>
                        <div className='pt-8 text-center'>
                            <a target = '_blank' href = "https://cad.onshape.com/documents/5f880871ba9fe11a91e8f2cd/w/5102a9031cf8e015fd3a94c5/e/9c05485ee701ad436ac2400b?renderMode=0&uiState=64c1f031f683a756ae663bcb">
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'> Demo </button>
                            </a>
                        </div>
                    </div>  
                </div>

                <div style = {{backgroundImage: `url(${WebsiteImg})`}}
                className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto text-center content-div'>
                    
                    {/* Hover Effects */}
                    <div className='opacity-0 group-hover:opacity-100'>
                        <span className='text-2xl font-bold text-white tracking-wider p-4'>
                            Website Portfolio
                        </span>
                        <div className='pt-8 text-center'>
                            <a href = "/">
                                <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'> Demo </button>
                            </a>
                        </div>
                    </div>  
                </div>

            </div>
        </div>
    </div>
  )
}

export default Work